	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/12/2021 04:51
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 50px;
  font-style: normal;
}

.flaticon-salad:before { content: "\f100"; }
.flaticon-script:before { content: "\f101"; }
.flaticon-school:before { content: "\f102"; }
.flaticon-medicine:before { content: "\f103"; }
.flaticon-shopping-bag:before { content: "\f104"; }
.flaticon-magnifying-glass:before { content: "\f105"; }
.flaticon-pin:before { content: "\f106"; }
.flaticon-call:before { content: "\f107"; }
.flaticon-envelope:before { content: "\f108"; }
.flaticon-play-button:before { content: "\f109"; }